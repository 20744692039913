export enum PROJECT_RESOURCE_CATEGORY {
  BUILD = 'build',
  ANALYZE = 'analyze',
  CONFIGURE = 'configure',
}

export enum PROJECT_RESOURCE {
  // ADMIN
  BILLING = 'billing',
  SECRETS = 'secrets',
  USER_MANAGEMENT = 'user_management',

  // BUILD
  KNOWLEDGE_BASE = 'knowledge_base',
  VOICE = 'voice',
  ABOUT = 'about',
  RULES = 'rules',
  SPEECH_RECOGNITION = 'speech_recognition',
  RESPONSE_CONTROL = 'response_control',
  FUNCTIONS = 'functions',
  HANDOFFS = 'handoffs',
  SMS = 'sms',
  FLOWS = 'jupiter_flows',

  // ANALYZE
  ANALYTICS = 'analytics',
  SAFETY_ANALYTICS = 'safety_analytics',
  CONVERSATIONS = 'conversations',
  PII = 'pii',

  // CONFIGURE
  AUDIO_MANAGEMENT = 'audio_cache',
  ENVIRONMENTS = 'environments',
  SETTINGS = 'settings',
}
