export const PATHS = {
  // Admin
  ASSISTANTS: '/:accountId?',
  BILLING: '/:accountId?/billing',
  SECRETS: '/:accountId?/secrets',
  USERS: '/:accountId?/users',

  // Assistant
  CREATE_ASSISTANT: '/:accountId/create-assistant',
  SAMPLE_PROJECT: '/:accountId/template-project/:projectId',

  // Build
  KNOWLEDGE_BASE: '/:accountId/:projectId/knowledge-base',
  VOICE: '/:accountId/:projectId/voice',
  ABOUT: '/:accountId/:projectId/about',
  RULES: '/:accountId/:projectId/rules',
  SPEECH_RECOGNITION: '/:accountId/:projectId/speech-recognition/:type',
  RESPONSE_CONTROL: '/:accountId/:projectId/response-control/:type',
  STOP_KEYWORD_CREATE_OR_UPDATE:
    '/:accountId/:projectId/response-control/phrase-filtering/:stopKeywordId',
  OVERWRITE_RULE_CREATE_OR_UPDATE:
    '/:accountId/:projectId/response-control/overwrite-rules/:overwriteRuleId',
  ASSISTANT_FUNCTIONS: '/:accountId/:projectId/functions',
  ASSISTANT_FUNCTION: '/:accountId/:projectId/functions/:functionId',
  ASSISTANT_FUNCTION_CREATE: '/:accountId/:projectId/functions/create',
  HANDOFF: '/:accountId/:projectId/handoff',
  SMS_TEMPLATES: '/:accountId/:projectId/sms-templates',
  FLOWS: '/:accountId/:projectId/flows',
  FLOW_DETAILS: '/:accountId/:projectId/flows/:flowId',

  // Analyze
  ANALYTICS_OVERVIEW: '/:accountId/:projectId/overview',
  ANALYTICS_SAFETY: '/:accountId/:projectId/assistant-safety',
  CONVERSATIONS: '/:accountId/:projectId/conversations',
  CONVERSATION_REVIEW: '/:accountId/:projectId/conversations/:conversationId',

  // Configure
  AUDIO_MANAGEMENT: '/:accountId/:projectId/audio-management',
  ENVIRONMENTS: '/:accountId/:projectId/environments',
  SETTINGS: '/:accountId/:projectId/settings',
  USER_SETTINGS: '/settings/user',
} as const;

export const PAGE_TITLES: { [key in keyof typeof PATHS]: string } = {
  // Admin
  ASSISTANTS: 'Assistants',
  BILLING: 'Billing',
  SECRETS: 'Secrets',
  USERS: 'Users',

  // Assistant
  CREATE_ASSISTANT: 'Create new assistant',
  SAMPLE_PROJECT: 'Template Project',

  // Build
  KNOWLEDGE_BASE: 'Knowledge Base',
  VOICE: 'Voice',
  ABOUT: 'About',
  RULES: 'Rules',
  SPEECH_RECOGNITION: 'Speech Recognition',
  RESPONSE_CONTROL: 'Response Control',
  STOP_KEYWORD_CREATE_OR_UPDATE: '',
  OVERWRITE_RULE_CREATE_OR_UPDATE: '',
  ASSISTANT_FUNCTIONS: 'Functions',
  ASSISTANT_FUNCTION: 'Function',
  ASSISTANT_FUNCTION_CREATE: 'Function',
  HANDOFF: 'Handoff',
  SMS_TEMPLATES: 'SMS messaging',
  FLOWS: 'Flows',
  FLOW_DETAILS: 'Flow details',

  // Analyze
  ANALYTICS_OVERVIEW: 'Overview',
  ANALYTICS_SAFETY: 'Assistant Safety',
  CONVERSATIONS: 'Conversations',
  CONVERSATION_REVIEW: 'Conversation',

  // Configure
  AUDIO_MANAGEMENT: 'Audio management',
  ENVIRONMENTS: 'Environments & Versions',
  SETTINGS: 'Assistant Settings',
  USER_SETTINGS: 'User Settings',
};

export const BASE_ROUTE = '/:accountId/:projectId/';
