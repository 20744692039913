import { useEffect } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'api';
import { setIsSiteUnavailable } from 'ducks/pageStatus/pageStatusSlice';
import {
  GLOBAL_PERMISSION,
  useGlobalPermissions,
} from 'hooks/useProjectResources/useGlobalPermissions';
import { isFeatureEnabled } from 'lib/featuresHelper';
import { useAppDispatch } from '../reduxHooks';

export type FeatureFlag =
  | 'advanced_code_editor'
  | 'barge_in'
  | 'call_handoff'
  | 'function_calls'
  | 'genai_voice'
  | 'import-kb-spreadsheet'
  | 'export-kb-spreadsheet'
  | 'jobs_to_be_done'
  | 'jupiter_analytics'
  | 'jupiter_safety_analytics'
  | 'jupiter_user_management'
  | 'mask_conversation_pii'
  | 'pronunciations'
  | 'read_only_view'
  | 'secrets_manager'
  | 'select_language'
  | 'sms_templates'
  | 'stripe-payments'
  | 'telephony'
  | 'topic_citations'
  | 'voice_configs'
  | 'content_filters'
  | 'disclaimer'
  | 'sms_templates_metrics'
  | 'stop_keywords'
  | 'overwrite_rules'
  | 'keyphrase_boosting'
  | 'transcript_corrections'
  | 'celebrate_deployment'
  | 'audio_cache_management';

export const useIsFeatureOn = () => {
  const dispatch = useAppDispatch();
  const { userHasGlobalPermission } = useGlobalPermissions();

  const queryClient = useQueryClient();

  const {
    data: features,
    isLoading: isLoadingFeatures,
    isError: didFeaturesError,
    isStale,
    refetch,
  } = useQuery(['features'], api.getFeatures, {
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 60000,
    onSuccess: (features) => {
      queryClient.setQueryData(['features'], features);
      const isUnderMaintenance = isFeatureEnabled(features, 'site_maintenance');
      dispatch(
        setIsSiteUnavailable(
          isUnderMaintenance &&
            !userHasGlobalPermission(GLOBAL_PERMISSION.PLATFORM_ADMIN),
        ),
      );
    },
  });

  useEffect(() => {
    if (isStale) {
      refetch().catch((e) =>
        console.error('Error occurred. Could not refetch query', e),
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isFeatureOn = (featureName: FeatureFlag) => {
    return isFeatureEnabled(features ?? [], featureName);
  };

  return {
    features,
    isLoadingFeatures,
    didFeaturesError,
    isFeatureOn,
  };
};
